import React from 'react'
import {Link} from 'react-router-dom'
import blueBg from '../all wallet/dappsBg.jpeg'
import { useState, useEffect } from "react";

export const Hero = () => {



  const [priceData, setPriceData] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin,binancecoin,ethereum,cardano,polygon,tether,xrp,solana,dogecoin,litecoin"
      );
      const data = await response.json();
      setPriceData(data);
    };
    fetchPrice();
  }, []);

  if (!priceData) {
    return <div>Loading...</div>;
  }

  return (
    <>
    <div className="relative h-[500px] bg-cover ">
  
        <img src={blueBg} 
        className="absolute h-full opacity-10 w-full"
        />
        
    
      <div className="relative h-[100%] flex items-center justify-center">
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-3xl mb-6 font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl">
              <span className="block pt-[70px]">Decentralized. Secure. Yours.</span>
              <span className="block text-white text-lg font-sans">Take control of your online identity with our decentralized website.</span>
            </h1>
            <div className="mt-10 flex justify-center ">
              <div className="inline-flex rounded-md shadow">

                <a className="inline-flex w-[150px] items-center justify-center px-5 mx-5 py-3 border  text-base font-medium rounded-md text-white bg-black border-orange-500 hover:bg-blue-900" > <Link to='/ArticleSection'>CLAIM</Link> </a>

                <a
                
                  className="inline-flex w-[150px] items-center justify-center px-5 py-3 border text-base font-medium rounded-md text-white bg-black border-orange-500 hover:bg-blue-900" ><Link to='/ArticleSection'>VERIFY</Link></a>
              </div>

              
            </div>

            <div className="mt-8 flex justify-center ">
              <div className="inline-flex rounded-md shadow">
                <a
                  
                  className="inline-flex w-[150px] items-center justify-center px-5 mx-5 py-3 border text-base font-medium rounded-md text-white bg-black border-orange-500 hover:bg-blue-900"
                >
                <Link to='ArticleSection'> SYNCHRONISE </Link>
                </a>

                <a
                  
                  className="inline-flex w-[150px] items-center justify-center px-5 py-3 border text-base font-medium rounded-md text-white bg-black border-orange-500 hover:bg-blue-900"
                >
                 <Link to='/ArticleSection'>  MIGRATE</Link>
                </a>
              </div>
              
            </div>
          </div>
        </div>

      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 px-2 bg-black ">
      {priceData.map((crypto) => (
        <div className="border p-4" key={crypto.id}>
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <img src={crypto.image} alt={crypto.name} className="w-6 h-6 mr-2 bg-white rounded-md" />
              <h3 className="text-white text-[11px]">{crypto.name}</h3>
            </div>
            <span className="text-green-500 font-bold pl-8 text-sm">{crypto.current_price}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-500">Volume:</span>
            <span className='text-white text-[12px] pl-2'>{crypto.total_volume}</span>
          </div>
          <div className="flex justify-between items-center mt-2">
            <span className="text-sm text-gray-500">7d Change:</span>
            <span className={crypto.price_change_percentage_7d > 0 ? "text-green-500" : "text-red-500"}>
              {crypto.price_change_percentage_7d}%
            </span>
          </div>
        </div>
      ))}
    </div>

    <div className='bg-black h-[100px] py-9'>
      <h3 className='text-center text-white text-2xl font-bold'>Decentralized. Secure. Yours.</h3>
      
    </div>
      
    </div>


    </>
  )
}
