import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import {Hero} from './components/Hero';
import Navbar from './components/Navbar';
import ArticleSection from './components/ArticleSection';
import Tabs from './components/Tabs'
import MyComponent from './components/MyComponent';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <BrowserRouter>
     <Navbar/>
      

        <Routes>
          <Route path='/' element={<App />} />
          <Route path='Hero' element={<Hero />} />
          <Route path='ArticleSection' element={<ArticleSection />} />
          <Route path='Mycomponent' element={<MyComponent/>} />
          <Route path='Tabs' element={<Tabs />} />
      
        

        </Routes>
    
    
    
    
    </BrowserRouter>
);

