import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <nav className="fixed bg-gradient-to-r from-orange-900 to-orange-800 border border-white z-10 w-screen">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between h-16">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <Link to={'/'}><img className="h-8 w-8 bg-white rounded-md" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="Workflow" /></Link>
          </div>
          <div className=" md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <a  className="bg-gray-900 text-white px-3 py-2 rounded-md text-[9px] font-bold border sm:text-[12px]"><Link to='/'>Home</Link></a>
              <a className="text-gray-300 hover:bg-blue-500 border border-white hover:text-white px-3 py-2 rounded-md text-[9px] font-medium sm:text-[12px]"><Link to='/ArticleSection'>Staking</Link></a>
              <a className="text-gray-300 hover:bg-blue-500 border border-white hover:text-white px-3 py-2 rounded-md text-[9px] font-medium sm:text-[12px]"><Link to='/ArticleSection'>Airdrop</Link></a>
            </div>
          </div>
        </div>
        <div className=" md:block">
          <div className="ml-4 flex items-center md:ml-6">
            <button className="bg-black border border-white text-white mx-4 px-3 py-2 rounded-md text-[9px] font-medium sm:text-[12px]"><Link to='/ArticleSection'>CONNECT</Link></button>
            <button className="hidden bg-black border border-white text-white px-3 py-2 rounded-md text-sm font-medium lg:visible"><Link to='/ArticleSection'>Migrate Token</Link></button>
          </div>
        </div>
      </div>
    </div>


    
  </nav>
  )
}

export default Navbar